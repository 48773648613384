import { useQuery } from '@tanstack/react-query';
import React from 'react';
import AppHeader from '../Components/Header/AppHeader';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import BookingActions from './BookingActions';
import { getAuthHeader } from '../Components/Auth/Auth';

// OverviewTable: an overview of all current bookings and their related BLs
export default function OverviewTable() {
  const { isLoading, error, data } = useQuery({
    queryKey: ['all_bookings'],
    queryFn: async () => {
      const authHeader = await getAuthHeader();
      const res = await fetch('/api/v1/bookings', {
        headers: authHeader,
      });
      return await res.json();
    },
  });

  if (isLoading) return <p>Loading data...</p>;

  if (error) {
    // @ts-ignore
    return <p>An error has occurred: {error.message}</p>;
  }
  const colDef: GridColDef[] = [
    {
      field: 'carrierBookingRequestReference',
      minWidth: 300,
      headerName: 'Booking reference',
    },
    {
      field: 'bookingRequestCreatedDateTime',
      minWidth: 200,
      headerName: 'Created at',
      valueFormatter: (params) => new Date(params.value).toLocaleString('nl'),
    },
    {
      field: 'bookingRequestUpdatedDateTime',
      minWidth: 200,
      headerName: 'Latest update',
      valueGetter: (params) => new Date(params.value).toLocaleString('nl'),
    },
    {
      field: 'documentStatus',
      minWidth: 150,
      headerName: 'Booking status',
    },
    {
      field: 'shippingInstruction',
      minWidth: 150,
      headerName: 'eBL status',
      valueGetter: (params) => params.row?.shippingInstruction?.documentStatus,
    },
    {
      field: 'from',
      minWidth: 150,
      headerName: 'From',
      valueGetter: () => 'eBL development',
    },
    {
      field: 'actions',
      minWidth: 700,
      headerName: 'Actions',
      renderCell: (params) => <BookingActions item={params.row} />,
    },
  ];

  return (
    <>
      <AppHeader />

      <div style={{ width: '100%' }}>
        <DataGrid
          rowSelection={false}
          sx={{
            overflowX: 'scroll',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 600,
            },
          }}
          rows={
            data
              ? data
              : [
                  {
                    carrierBookingRequestReference: 'No data added yet',
                  },
                ]
          }
          columns={colDef}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
            sorting: {
              sortModel: [{ field: 'bookingRequestCreatedDateTime', sort: 'desc' }],
            },
          }}
          getRowId={(row) => row.carrierBookingRequestReference}
          pageSizeOptions={[10, 10]}
        />
      </div>
    </>
  );
}
