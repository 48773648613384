import React from 'react';
import { Alert } from '@mui/material';
import { getNetworkName } from '../TradeTrust/tokenRegistry';

interface props {
  verifying: boolean;
  verified: boolean;
}

// EblVerifyMessage is a UI component that displays the current status of an ebl on the blockchain.
// E.g. whether it is verified, who the current owner is and so forth.
export default function EblVerifyMessage(props: props) {
  if (props.verifying) {
    return <p>Verifying document...</p>;
  } else {
    if (props.verified) {
      return (
        <Alert severity="success">
          Document verified on {getNetworkName(parseInt(window.ethereum?.networkVersion))}
        </Alert>
      );
    } else {
      return (
        <Alert severity="error">
          Document not verified on {getNetworkName(parseInt(window.ethereum?.networkVersion))}
        </Alert>
      );
    }
  }
}
