import { Route } from './Types/Route';
import OverviewTable from './OverviewTable/OverviewTable';

export const routes: Array<Route> = [
  {
    key: 'overview',
    title: 'Overview',
    description: 'OverviewTable',
    component: <OverviewTable />,
    path: '/',
    enabled: true,
    isHidden: true,
  },
];
