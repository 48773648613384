import BookingContentsModal from './BookingContentsModal';
import ReplyToBookingModal from './ReplyToBookingModal';
import IssueEblModal from './IssueEblModal';
import DraftEblModal from './DraftEblModal';
import BreakBulkEblModal from './BreakBulkEblModal';
import WrappedEblModal from './WrappedEblModal';
import React from 'react';
import { Grid } from '@mui/material';

export default function BookingActions(props: props) {
  function canDraftEbl(documentStatus: string) {
    return documentStatus === 'REQS';
  }

  function canViewEbl(documentStatus: string) {
    return documentStatus === 'ISSU' || documentStatus === 'APPR' || documentStatus === 'DRFT';
  }

  function canWrapEbl(documentStatus: string) {
    return documentStatus === 'ISSU';
  }

  function canIssueEbl(documentStatus: string) {
    return documentStatus === 'APPR';
  }

  return (
    <Grid container spacing={1} sx={{ width: '100%' }}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item>
            <BookingContentsModal
              bookingObject={props.item['bookingObject']}
              shippingInstruction={props.item['shippingInstruction']?.DCSAShippingInstruction}
            />
          </Grid>

          <Grid item>
            <ReplyToBookingModal carrierRef={props.item['carrierBookingRequestReference']} />
          </Grid>

          {canIssueEbl(props.item['shippingInstruction']?.documentStatus) && (
            <Grid item>
              <IssueEblModal
                SIRef={props.item['shippingInstruction']?.shippingInstructionReference}
              />
            </Grid>
          )}
          {canDraftEbl(props.item['shippingInstruction']?.documentStatus) && (
            <Grid item>
              <DraftEblModal
                SIRef={props.item['shippingInstruction']?.shippingInstructionReference}
              />
            </Grid>
          )}
          {canViewEbl(props.item['shippingInstruction']?.documentStatus) && (
            <Grid item>
              <BreakBulkEblModal eblObject={props.item['shippingInstruction']?.breakBulkEbl} />
            </Grid>
          )}
          {canWrapEbl(props.item['shippingInstruction']?.documentStatus) && (
            <Grid item>
              <WrappedEblModal si={props.item['shippingInstruction']} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

interface props {
  item: any;
}
