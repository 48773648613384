import { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from '@mui/material';
import { DocumentRenderer } from '../Components/DocumentRenderer/DocumentRenderer';
import '../Components/DocumentRenderer/DocumentRenderer.css';
import { parseJSONContents } from '../util';

interface Props {
  bookingObject: string;
  shippingInstruction: string;
}

interface BookingDialogProps {
  open: boolean;
  onClose: () => void;
  tabIndex: number;
  onTabChange: (newValue: number) => void;
  bookingObject: any;
  shippingInstruction: string;
}

interface TabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
}

interface RenderTabProps {
  bookingObject: any;
  shippingInstruction: string;
}

interface JSONTabProps {
  bookingObject: any;
  shippingInstruction: string;
}

interface ShippingInstructionsProps {
  shippingInstruction: string;
}

export default function BookingContentsModal({ bookingObject, shippingInstruction }: Props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const parsedBookingObject = parseJSON(bookingObject);
  const parsedSIObject = parseJSON(shippingInstruction);

  // If carrierBookingReference is available, add it to bookingObject
  if (parsedSIObject?.carrierBookingReference && parsedBookingObject) {
    parsedBookingObject.carrierBookingReference = parsedSIObject.carrierBookingReference;
  }

  return (
    <>
      <ShowModalButton onClick={() => setModalOpen(true)} />
      <BookingDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        tabIndex={tabIndex}
        onTabChange={setTabIndex}
        bookingObject={parsedBookingObject}
        shippingInstruction={shippingInstruction}
      />
    </>
  );
}

function parseJSON(jsonString: string) {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return null;
  }
}

function ShowModalButton({ onClick }: { onClick: () => void }) {
  return (
    <Button size="small" variant="outlined" onClick={onClick}>
      Show contents
    </Button>
  );
}

function BookingDialog({
  open,
  onClose,
  tabIndex,
  onTabChange,
  bookingObject,
  shippingInstruction,
}: BookingDialogProps) {
  return (
    <Dialog onClose={onClose} maxWidth="md" fullWidth open={open}>
      <DialogTitle>Booking contents</DialogTitle>
      <DialogContent dividers>
        <Tabs value={tabIndex} onChange={(_, newValue) => onTabChange(newValue)}>
          <Tab label="Render" />
          <Tab label="JSON" />
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          <RenderTab bookingObject={bookingObject} shippingInstruction={shippingInstruction} />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <JSONTab bookingObject={bookingObject} shippingInstruction={shippingInstruction} />
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

function TabPanel({ children, value, index }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box padding={1}>{children}</Box>}
    </div>
  );
}

function RenderTab({ bookingObject, shippingInstruction }: RenderTabProps) {
  return (
    <DocumentRenderer
      wrappedDocument={undefined}
      documentName="Booking Request"
      overrideDocumentData={bookingObject}
      overrideTemplateType="BOOKING_REQUEST"
      overrideTemplateUrl="https://decentralized-renderer.ebl.dev/"
      jsonBottomElement={<ShippingInstructions shippingInstruction={shippingInstruction} />}
    />
  );
}

function JSONTab({ bookingObject, shippingInstruction }: JSONTabProps) {
  return (
    <>
      <pre>{JSON.stringify(bookingObject, null, 2)}</pre>
      <ShippingInstructions shippingInstruction={shippingInstruction} />
    </>
  );
}

function ShippingInstructions({ shippingInstruction }: ShippingInstructionsProps) {
  return (
    <div>
      <h3>Shipping instructions</h3>
      <pre>
        {shippingInstruction ? parseJSONContents(shippingInstruction) : 'Contents not found'}
      </pre>
    </div>
  );
}
