/* eslint-disable jsx-a11y/no-autofocus,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import { convertToWrappedDocument, postWrappedDoc } from '../TradeTrust/wrapDoc';
import React, { useEffect, useState } from 'react';
import { useConnectWallet, useWallets } from '@web3-onboard/react';
import { useQueryClient } from '@tanstack/react-query';
import EblVerifyMessage from './EblVerifyMessage';
import { verifyDoc } from '../TradeTrust/verifyDoc';
import { initTokenRegistry, ttTokenRegistry } from '../TradeTrust/tokenRegistry';
import { getData, WrappedDocument } from '@govtechsg/open-attestation';
import { ethers } from 'ethers';
import _ from 'lodash';
import './WrappedEblModal.css';
import { DocumentRenderer } from '../Components/DocumentRenderer/DocumentRenderer';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

export default function WrappedEblModal(props: props) {
  const queryClient = useQueryClient();
  const [modalOpen, setModalOpen] = useState(false);
  const [wrappedDoc, setWrappedDoc] = useState();
  const [{ wallet }, connect, disconnect] = useConnectWallet();
  useWallets();
  const [verifying, setVerifying] = useState<boolean>(true);
  const [verified, setVerified] = useState<boolean>(false);
  const [TTFileContents, setTTFileContents] = useState<string>();
  const [TTFileName, setTTFileName] = useState<string>();
  const [beneficiaryAddress, setBeneficiaryAddress] = useState<string>('');
  const [holderAddress, setHolderAddress] = useState<string>('');
  const [beneficiaryCustomInput, setBeneficiaryCustomInput] = useState<boolean>(false);
  const [beneficiaryInputShowError, setBeneficiaryInputShowError] = useState<boolean>(false);
  const [holderInputShowError, setHolderInputShowError] = useState<boolean>(false);
  const [holderCustomInput, setHolderCustomInput] = useState<boolean>(false);
  const [shipperAddress, setShipperAddress] = useState<string>();
  const [consigneeAddress, setConsigneeAddress] = useState<string>();
  const [notifyPartyAddress, setNotifyPartyAddress] = useState<string>();

  const handleWallet = () => {
    setModalOpen(false);
    if (wallet) {
      disconnect(wallet);
      window.localStorage.removeItem('connectedWallets');
    } else {
      connect().then((r) => {
        const provider = r[0].provider;
        if (provider !== undefined) {
          const ethersProvider = new ethers.providers.Web3Provider(provider);
          const signer = ethersProvider.getSigner();
          initTokenRegistry(signer);
        }
      });
    }
  };

  let holderDebounceTimer: NodeJS.Timeout, beneficiaryDebounceTimer: NodeJS.Timeout;

  // In order to make all radio buttons non-overlapping, they need to have unique values.
  // This is done by adding a prefix like notifyParty-0x.... to the string. Here we remove that prefix.
  function formatAddress(address: string): string {
    if (address.includes('-')) {
      return address.substring(address.indexOf('-') + 1);
    }
    return address;
  }

  useEffect(() => {
    if (wrappedDoc != undefined) {
      verify(wrappedDoc);
    }
  }, [wrappedDoc, wallet]);

  function showModal() {
    let w;
    if (props.si['breakBulkWrappedEbl']) {
      // This means the wrapped ebl has been created earlier, so we can just display it here
      w = JSON.parse(props.si['breakBulkWrappedEbl']);
      setWrappedDoc(w);
    } else {
      // Wrapped eBL not yet found; we need to wrap it and then save it to DB
      w = convertToWrappedDocument(props.si['breakBulkEbl']);
      setWrappedDoc(w);
      postWrappedDoc(props.si['shippingInstructionReference'], w)
        .then((r) => {
          queryClient.invalidateQueries(['all_bookings']);
          console.log('Successful callback: ' + JSON.stringify(r.json()));
        })
        .catch((r) => {
          console.warn('Error in posting wrapped eBL: ' + r);
        });
    }

    const tokenId = w['signature']['merkleRoot'];
    setTTFileContents('data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(w)));
    setTTFileName('ebl-' + tokenId + '.tt');

    const documentData = getData(w);

    const shipperAddressFromDoc = _.get(
      documentData,
      'oaDocument.shipper.party.publicKey',
      undefined,
    );
    setShipperAddress(shipperAddressFromDoc);
    const consigneeAddressFromDoc = _.get(
      documentData,
      'oaDocument.consignee.party.publicKey',
      undefined,
    );
    setConsigneeAddress(consigneeAddressFromDoc);
    const notifyPartyAddressFromDoc = _.get(
      documentData,
      'oaDocument.notifyParty.party.publicKey',
      undefined,
    );
    setNotifyPartyAddress(notifyPartyAddressFromDoc);

    setModalOpen(true);
  }

  async function issueDocument(doc: WrappedDocument<any>) {
    const tokenRegistry = ttTokenRegistry;
    if (!tokenRegistry) {
      console.error('Token registry not yet initialized');
      return;
    }
    if (!beneficiaryAddress || !holderAddress) {
      console.error('Beneficiary or holder address not found');
      return;
    }
    const docHash = doc.signature.merkleRoot;

    try {
      console.log(
        `Attempting to issue document: ${docHash} 
        as beneficiary: ${beneficiaryAddress}, 
        as holder: ${holderAddress}`,
      );
      const tx = await tokenRegistry.mint(beneficiaryAddress, holderAddress, '0x' + docHash);
      setVerifying(true);
      await tx.wait(1);
      await verify(doc);
    } catch (e) {
      console.error('Error minting token: ' + e);
    }
  }

  async function verify(doc: WrappedDocument<any>) {
    console.log('Optimistic block confirmation succeeded');
    try {
      const chainId = window.ethereum?.networkVersion;
      if (!chainId) {
        console.error('Wallet is not connected to a network');
        setVerifying(false);
        setVerified(false);
        return;
      }
      console.log('Verifying doc using oa-verify, on network ' + parseInt(chainId));
      const r = await verifyDoc(doc, parseInt(chainId));
      setVerifying(false);

      if (r.summary && r.summary.all) {
        setVerified(true);
      }
    } catch (err) {
      setVerifying(false);
      setVerified(false);
    }
  }

  const selectBeneficiaryAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const address = (e.target as HTMLInputElement).value;

    if (address === 'custom') {
      setBeneficiaryCustomInput(true);
      return;
    }

    setBeneficiaryCustomInput(false);
    onBeneficiaryAddressChange(address);
  };

  const selectHolderAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const address = (e.target as HTMLInputElement).value;

    if (address === 'custom') {
      setHolderCustomInput(true);
      return;
    }

    setHolderCustomInput(false);
    onHolderAddressChange(address);
  };

  const onBeneficiaryAddressChange = (address: string) => {
    setBeneficiaryInputShowError(false);

    // Clear the existing debounce timer
    clearTimeout(beneficiaryDebounceTimer);

    // Start a new debounce timer
    beneficiaryDebounceTimer = setTimeout(() => {
      beneficiaryDebounce(address);
    }, 1000);
  };

  const beneficiaryDebounce = (address: string) => {
    console.log('Beneficiary address change: ' + address);

    address = formatAddress(address);

    try {
      address = ethers.utils.getAddress(address);

      setBeneficiaryAddress(address);
      setBeneficiaryInputShowError(false);
    } catch (err) {
      console.error('Invalid beneficiary address: ' + err);
      setBeneficiaryAddress('');
      setBeneficiaryInputShowError(true);
    }
  };

  const onHolderAddressChange = (address: string) => {
    setHolderInputShowError(false);

    // Clear the existing debounce timer
    clearTimeout(holderDebounceTimer);

    // Start a new debounce timer
    holderDebounceTimer = setTimeout(() => {
      holderDebounce(address);
    }, 1000);
  };

  const holderDebounce = (address: string) => {
    console.log('Holder address change: ' + address);

    address = formatAddress(address);

    try {
      address = ethers.utils.getAddress(address);

      setHolderAddress(address);
      setHolderInputShowError(false);
    } catch (err) {
      console.error('Invalid holder address: ' + err);
      setHolderAddress('');
      setHolderInputShowError(true);
    }
  };

  const downloadTTFile = () => {
    const link = document.createElement('a');
    link.download = TTFileName || '';
    link.href = TTFileContents || '';
    link.click();
  };

  const walletAddr = wallet?.accounts[0].address;

  return (
    <>
      <Button variant="outlined" size="small" onClick={showModal}>
        Wrap eBL
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        disableEscapeKeyDown
        onClose={() => {
          setHolderAddress('');
          setBeneficiaryAddress('');
          setModalOpen(false);
        }}
        open={modalOpen}
      >
        <DialogTitle>Wrap eBL</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText>Break Bulk eBL</DialogContentText>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <DocumentRenderer
                wrappedDocument={wrappedDoc}
                documentName="eBL"
                overrideTemplateType="BILL_OF_LADING_BIMCO_DRAFT"
              />
            </Grid>
            <Grid className="noprint" item>
              <Button onClick={downloadTTFile} variant="contained">
                Download as .tt file
              </Button>
              <Button style={{ marginLeft: 5 }} onClick={() => print()} variant="contained">
                Download as PDF
              </Button>
            </Grid>
          </Grid>
          {!verifying && !verified && (
            <>
              <Grid container direction="column" spacing={1} padding={1}>
                <Grid item>
                  <Grid item>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">Issue this eBL</Typography>
                  </Grid>
                  <FormControl fullWidth>
                    <FormLabel id="beneficiary-address-label">Select owner address:</FormLabel>
                    <RadioGroup
                      onChange={selectBeneficiaryAddress}
                      aria-labelledby="beneficiary-address-label"
                      defaultValue=""
                      name="radio-buttons-group"
                    >
                      {shipperAddress && (
                        <FormControlLabel
                          name="shipper"
                          value={'shipper-' + shipperAddress}
                          control={<Radio size="small" />}
                          label={`Shipper from eBL (${shipperAddress})`}
                        />
                      )}
                      {/* Optional notify party address from eBL contents */}
                      {notifyPartyAddress && (
                        <FormControlLabel
                          name="notify"
                          value={'notifyParty-' + notifyPartyAddress}
                          control={<Radio size="small" />}
                          label={`Notify party from eBL (${notifyPartyAddress})`}
                        />
                      )}
                      {/* Optional consignee address from eBL contents */}
                      {consigneeAddress && (
                        <FormControlLabel
                          name="consignee"
                          value={'consignee-' + consigneeAddress}
                          control={<Radio size="small" />}
                          label={`Consignee from eBL (${consigneeAddress})`}
                        />
                      )}
                      <FormControlLabel
                        name="wallet"
                        value={walletAddr || ethers.constants.AddressZero}
                        control={<Radio size="small" />}
                        label={`Use wallet address (${
                          walletAddr ? walletAddr : ethers.constants.AddressZero
                        })`}
                      />
                      <FormControlLabel
                        name="custom"
                        value="custom"
                        control={<Radio size="small" />}
                        label="Custom address"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item>
                  {beneficiaryCustomInput && (
                    <TextField
                      fullWidth
                      onChange={(e) => onBeneficiaryAddressChange(e.target.value)}
                      placeholder={ethers.constants.AddressZero}
                      label="Custom address"
                      autoFocus
                      error={beneficiaryInputShowError}
                    />
                  )}
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormLabel id="holder-address-label">Select holder address:</FormLabel>
                    <RadioGroup
                      onChange={selectHolderAddress}
                      aria-labelledby="holder-address-label"
                      defaultValue=""
                      name="radio-buttons-group"
                    >
                      {shipperAddress && (
                        <FormControlLabel
                          name="shipper"
                          value={'shipper-' + shipperAddress}
                          control={<Radio size="small" />}
                          label={`Shipper from eBL (${shipperAddress})`}
                        />
                      )}
                      {/* Optional notify party address from eBL contents */}
                      {notifyPartyAddress && (
                        <FormControlLabel
                          name="notify"
                          value={'notifyParty-' + notifyPartyAddress}
                          control={<Radio size="small" />}
                          label={`Notify party from eBL (${notifyPartyAddress})`}
                        />
                      )}
                      {/* Optional consignee address from eBL contents */}
                      {consigneeAddress && (
                        <FormControlLabel
                          name="consignee"
                          value={'consignee-' + consigneeAddress}
                          control={<Radio size="small" />}
                          label={`Consignee from eBL (${consigneeAddress})`}
                        />
                      )}
                      <FormControlLabel
                        name="wallet"
                        value={walletAddr || ethers.constants.AddressZero}
                        control={<Radio size="small" />}
                        label={`Use wallet address (${
                          walletAddr ? walletAddr : ethers.constants.AddressZero
                        })`}
                      />
                      <FormControlLabel
                        name="custom"
                        value="custom"
                        control={<Radio size="small" />}
                        label="Custom address"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item>
                  {holderCustomInput && (
                    <TextField
                      fullWidth
                      onChange={(e) => onHolderAddressChange(e.target.value)}
                      placeholder={ethers.constants.AddressZero}
                      label="Custom address"
                      autoFocus
                      error={holderInputShowError}
                    />
                  )}
                </Grid>
                <Grid item>
                  {walletAddr ? (
                    beneficiaryAddress && holderAddress ? (
                      <Button variant="contained" onClick={() => issueDocument(wrappedDoc)}>
                        Issue Document
                      </Button>
                    ) : (
                      <Tooltip
                        placement="top"
                        title={
                          <Typography variant="body2">
                            Please enter owner and holder address
                          </Typography>
                        }
                        arrow
                      >
                        <span>
                          <Button
                            variant="contained"
                            onClick={() => issueDocument(wrappedDoc)}
                            disabled
                          >
                            Issue Document
                          </Button>
                        </span>
                      </Tooltip>
                    )
                  ) : (
                    <Tooltip
                      placement="top"
                      title={
                        <Typography variant="body2">
                          Please{' '}
                          <Link onClick={handleWallet} underline="hover" color="inherit" href="#">
                            <strong>connect a wallet</strong>
                          </Link>{' '}
                          to perform this action
                        </Typography>
                      }
                      arrow
                    >
                      <span>
                        <Button
                          variant="contained"
                          onClick={() => issueDocument(wrappedDoc)}
                          disabled
                        >
                          Issue Document
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </>
          )}
          <Box sx={{ paddingTop: 2 }}>
            <EblVerifyMessage verifying={verifying} verified={verified} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

interface props {
  si: any; // shippingInstruction object
}
