import { getAuthHeader } from '../Components/Auth/Auth';

export async function patchEbl(SIRef: string, shippedOnBoardDate: string): Promise<Response> {
  const authHeader = await getAuthHeader();
  return fetch('/api/v2/shipping-instructions/' + SIRef, {
    method: 'PATCH',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authHeader.Authorization,
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({
      shippedOnBoardDate: shippedOnBoardDate,
    }),
  });
}
