import { wrapDocument, WrappedDocument } from '@govtechsg/open-attestation';
import { TemplateType } from '@govtechsg/open-attestation/dist/types/__generated__/schema.2.0';
import { transportDocumentsEndpoint } from '../config';
import { defaultDnsProofLocation, getTokenRegistryAddress } from './tokenRegistry';
import { getAuthHeader } from '../Components/Auth/Auth';

enum IdentityProofType {
  DNSDid = 'DNS-DID',
  DNSTxt = 'DNS-TXT',
  Did = 'DID',
}

export function convertToWrappedDocument(doc: string): WrappedDocument<any> {
  const oaDocument = JSON.parse(doc);
  const tokenRegistryAddress = getTokenRegistryAddress(parseInt(window.ethereum.networkVersion));
  if (!tokenRegistryAddress) {
    console.error(
      'Token registry address is not found; is the wallet connected to a supported network?',
    );
    return;
  }

  let blNo = '';
  if (oaDocument.hasOwnProperty('BillOfLadingNo')) {
    blNo = oaDocument['BillOfLadingNo'];
  }

  const documentUrl = `${transportDocumentsEndpoint}/${blNo}`;
  const oaDoc = {
    id: blNo,
    documentUrl: documentUrl,
    $template: {
      type: 'EMBEDDED_RENDERER' as TemplateType,
      name: 'BILL_OF_LADING_BIMCO',
      url: 'https://decentralized-renderer.ebl.dev/',
    },
    issuers: [
      {
        name: 'eBL test',
        // Token registry deployed on Sepolia test network
        tokenRegistry: tokenRegistryAddress,
        identityProof: {
          type: IdentityProofType.DNSTxt,
          location: defaultDnsProofLocation,
        },
      },
    ],
    oaDocument: {
      ...Object.fromEntries(Object.entries(oaDocument).map(([key, value]) => [key, value])),
    },
  };

  return wrapDocument(oaDoc);
}

export async function postWrappedDoc(siRef: string, w: any): Promise<Response> {
  const authHeader = await getAuthHeader();
  return fetch('/api/v2/shipping-instructions/' + siRef + '/wrap', {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authHeader.Authorization,
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({
      wrappedDoc: JSON.stringify(w),
    }),
  });
}
