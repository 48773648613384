const oaVerifyHost = process.env.REACT_APP_OA_VERIFY_HOST || 'https://oa-verify.ebl.dev';
export function getVerifyEndpoint(chainId: number): string {
  // By default, we verify the doc on the ethereum network
  let network = 'ethereum';
  if (chainId === 11155111) {
    network = 'sepolia';
  }
  if (chainId === 80001) {
    network = 'maticmum';
  }

  return `${oaVerifyHost}/verify?network=${network}`;
}
