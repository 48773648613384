import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import config from './Wallet/config';
import { Web3OnboardProvider } from '@web3-onboard/react';
import { User, onAuthStateChanged } from 'firebase/auth';
import { auth } from './Components/Auth/Auth';
import { CircularProgress } from '@mui/material';
import Login from './Login';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const Main = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log('User logged in: ' + user);
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <React.StrictMode>
      <Web3OnboardProvider web3Onboard={config}>{user ? <App /> : <Login />}</Web3OnboardProvider>
    </React.StrictMode>
  );
};

root.render(<Main />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
