import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { patchEbl } from '../TradeTrust/ebl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

// Select a booking to reply to
// props.selectedBookingRef - the BookingCarrierReference
export default function IssueEblModal(props: props) {
  const queryClient = useQueryClient();
  const [modalOpen, setModalOpen] = useState(false);

  const issueEbl = function () {
    patchEbl(props.SIRef, '')
      .then((r) => {
        queryClient.invalidateQueries(['all_bookings']);
        console.log('Successful callback: ' + JSON.stringify(r.json()));
      })
      .catch((r) => {
        console.warn('Error in draft eBL: ' + r);
      });

    setModalOpen(false);
  };

  return (
    <>
      <Button variant="outlined" size="small" onClick={() => setModalOpen(true)}>
        Issue eBL
      </Button>
      <Dialog onClose={() => setModalOpen(false)} open={modalOpen}>
        <DialogTitle>Issue eBL</DialogTitle>
        <DialogContent>
          <DialogContentText>Finalize this eBL by issuing it</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
          <Button onClick={() => issueEbl()}>Issue eBL</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

interface props {
  SIRef: string;
}
