import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
} from '@mui/material';
import { getAuthHeader } from '../Components/Auth/Auth';

export default function ReplyToBookingModal(props: props) {
  const [modalOpen, setModalOpen] = useState(false);

  const [reason, setReason] = useState('');
  const handleChangeReason = function (event: any) {
    setReason(event.target.value);
  };

  const sendFeedback = async function (newStatus?: string) {
    const authHeader = await getAuthHeader();
    fetch('/api/v1/bookings/' + props.carrierRef, {
      method: 'PATCH',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: authHeader.Authorization,
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        bookingDocumentStatus: newStatus,
        eblDocumentStatus: '',
        reason: reason,
      }),
    })
      .then((r) => {
        console.log('Successful callback: ' + JSON.stringify(r.json()));
      })
      .catch((r) => {
        console.warn('Error in draft eBL: ' + r);
      });

    setReason('');
    setModalOpen(false);
  };

  return (
    <>
      <Button variant="outlined" size="small" onClick={() => setModalOpen(true)}>
        Reply to booking
      </Button>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>Reply to this booking request</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Reason for accepting/denying the booking request
            <Input
              type="text"
              value={reason}
              onChange={handleChangeReason}
              fullWidth
              placeholder="(Optional) message to respond to shipper"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)}>Cancel</Button>
          <Button onClick={() => sendFeedback('CONF')} variant="contained">
            Confirm
          </Button>
          <Button color="error" onClick={() => sendFeedback('REJE')} variant="contained">
            Reject
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

interface props {
  carrierRef: string;
}
