export function parseJSONContents(input: string) {
  return JSON.stringify(JSON.parse(input), null, 2);
}

export function showShortWalletAddr(walletAddr: string | undefined): string {
  if (walletAddr && walletAddr.length < 6) {
    return walletAddr;
  }
  const shortWalletAddr = `${walletAddr?.slice(0, 5)}...${walletAddr?.slice(-5)}`;
  return shortWalletAddr;
}
