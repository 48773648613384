import { useState } from 'react';
import { patchEbl } from '../TradeTrust/ebl';
import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
} from '@mui/material';

export default function DraftEblModal(props: props) {
  const queryClient = useQueryClient();
  const [modalOpen, setModalOpen] = useState(false);
  const [shippedOnBoardDate, setShippedOnBoardDate] = useState('');

  const handleChangeShippedOnBoardDate = function (event: any) {
    setShippedOnBoardDate(event.target.value);
  };

  const draftEbl = function () {
    patchEbl(props.SIRef, shippedOnBoardDate)
      .then((r) => {
        queryClient.invalidateQueries(['all_bookings']);
        console.log('Successful callback: ' + JSON.stringify(r.json()));
      })
      .catch((r) => {
        console.warn('Error in draft eBL: ' + r);
      });

    setShippedOnBoardDate('');
    setModalOpen(false);
  };

  return (
    <>
      <Button variant="outlined" size="small" onClick={() => setModalOpen(true)}>
        Draft eBL
      </Button>
      <Dialog onClose={() => setModalOpen(false)} open={modalOpen}>
        <DialogTitle>Draft eBL</DialogTitle>
        <DialogContent>
          <DialogContentText>Shipped on board date</DialogContentText>
          <Input
            type="date"
            value={shippedOnBoardDate}
            onChange={handleChangeShippedOnBoardDate}
            placeholder="YYYY-MM-DD"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={() => draftEbl()}>
            Issue eBL
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

interface props {
  SIRef: string;
}
