import injectedModule from '@web3-onboard/injected-wallets';
import { init } from '@web3-onboard/react';
import icon from './blocknative-logo';

const injected = injectedModule();

export default init({
  wallets: [injected],
  chains: [
    {
      id: 80001,
      token: 'MATIC',
      label: 'Polygon Mumbai',
      rpcUrl: 'wss://polygon-mumbai-bor.publicnode.com',
    },
    {
      id: '0xaa36a7',
      token: 'SepoliaETH',
      label: 'Sepolia',
      rpcUrl: 'https://rpc.sepolia.org/',
    },
    {
      id: '0x33',
      token: 'XDCApothem',
      label: 'XDC Apothem',
      rpcUrl: 'https://rpc.apothem.network/',
    },
  ],
  accountCenter: {
    desktop: {
      position: 'bottomRight',
      enabled: true,
      minimal: false,
    },
    mobile: {
      position: 'bottomRight',
      enabled: true,
      minimal: false,
    },
  },
  appMetadata: {
    name: 'Carrier Frontend',
    icon,
    description: 'Carrier Frontend',
    recommendedInjectedWallets: [
      {
        name: 'MetaMask',
        url: 'https://metamask.io',
      },
    ],
  },
  theme: 'light',
});
