import React, { useState } from 'react';
import { parseJSONContents } from '../util';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export default function BreakBulkEblModal(props: props) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button variant="outlined" size="small" onClick={() => setModalOpen(true)}>
        Show eBL
      </Button>
      <Dialog
        onClose={() => setModalOpen(false)}
        open={modalOpen}
        scroll="paper"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>eBL contents</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText>Inspect contents of this break bulk eBL</DialogContentText>
          <pre>{props.eblObject ? parseJSONContents(props.eblObject) : 'Contents not found'}</pre>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

interface props {
  eblObject: any;
}
