import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material';
import WalletButton from '../../Wallet/walletButton';
import { signOut as firebaseSignOut } from 'firebase/auth';
import { DirectionsBoat, Logout } from '@mui/icons-material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import React from 'react';
import { auth } from '../Auth/Auth';

async function signOut() {
  try {
    await firebaseSignOut(auth);
    document.location.href = '/';
    // eslint-disable-next-line
  } catch (error: any) {
    console.error('Error signing out:', error.message);
  }
}

export default function AppHeader() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <DirectionsBoat sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Carrier dashboard
          </Typography>
          <WalletButton
            sx={{ marginRight: 1 }}
            variant="contained"
            color="info"
            startIcon={<AccountBalanceWalletIcon />}
          />
          <Button color="info" variant="contained" startIcon={<Logout />} onClick={() => signOut()}>
            Sign out
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
