import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { routes } from './routes';
import { Route as AppRoute } from './Types/Route';

const queryClient = new QueryClient();

// Add the polygon mumbai network to the metamask wallet,
// and request the user to set it as the default
// window.ethereum?.request({
//   method: 'wallet_addEthereumChain',
//   params: [
//     {
//       chainId: '0x13881',
//       chainName: 'Mumbai',
//       rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
//       iconUrls: [],
//       nativeCurrency: {
//         name: 'MATIC',
//         symbol: 'MATIC',
//         decimals: 18,
//       },
//       blockExplorerUrls: ['https://mumbai.polygonscan.com'],
//     },
//   ],
// });

function App() {
  const addRoute = (route: AppRoute) => (
    <Route key={route.key} path={route.path} element={route.component} />
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          {routes.map((route: AppRoute) =>
            route.subRoutes
              ? route.subRoutes.map((item: AppRoute) => addRoute(item))
              : addRoute(route),
          )}
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
