import { getVerifyEndpoint } from './config';
import { WrappedDocument } from '@govtechsg/open-attestation';
import { getAuthHeader } from '../Components/Auth/Auth';

export async function verifyDoc(wrappedDoc: WrappedDocument<any>, chainId: number): Promise<any> {
  const authHeader = await getAuthHeader();
  const response = await fetch(getVerifyEndpoint(chainId), {
    method: 'POST',
    headers: {
      Authorization: authHeader.Authorization,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ document: wrappedDoc }),
  });

  return response.json();
}
