import React, { useEffect } from 'react';
import { useConnectWallet, useWallets } from '@web3-onboard/react';
import { initTokenRegistry } from '../TradeTrust/tokenRegistry';
import { ethers } from 'ethers';
import { Button } from '@mui/material';

export default function WalletButton(props: any) {
  const [w, connect, disconnect] = useConnectWallet();
  const connectedWallets = useWallets();

  useEffect(() => {
    if (!connectedWallets.length) return;
    const connectedWalletsLabelArray = connectedWallets.map(({ label }) => label);
    window.localStorage.setItem('connectedWallets', JSON.stringify(connectedWalletsLabelArray));
  }, [w.wallet, connectedWallets]);

  useEffect(() => {
    if (!connect || connectedWallets?.length > 0) return;
    const previousConnectedWallets = JSON.parse(
      window.localStorage.getItem('connectedWallets') || '[]',
    ) as string[];

    if (previousConnectedWallets?.length) {
      connect({
        autoSelect: {
          label: previousConnectedWallets[0],
          disableModals: true,
        },
      }).then((r) => {
        const provider = r[0].provider;
        if (provider !== undefined) {
          const ethersProvider = new ethers.providers.Web3Provider(provider);
          const signer = ethersProvider.getSigner();
          initTokenRegistry(signer);
        }
      });
    }
    // eslint-disable-next-line
  }, [connect]);

  useEffect(() => {
    if (w.wallet == null) {
      window.localStorage.removeItem('connectedWallets');
    }
  }, [w.wallet]);

  const handleWallet = () => {
    if (w.wallet) {
      disconnect(w.wallet);
      window.localStorage.removeItem('connectedWallets');
    } else {
      connect().then((r) => {
        const provider = r[0].provider;
        if (provider !== undefined) {
          const ethersProvider = new ethers.providers.Web3Provider(provider);
          const signer = ethersProvider.getSigner();
          initTokenRegistry(signer);
        }
      });
    }
  };

  return (
    <Button {...props} primary onClick={handleWallet}>
      {w.connecting ? 'Connecting wallet' : w.wallet ? 'Disconnect wallet' : 'Connect wallet'}
    </Button>
  );
}
