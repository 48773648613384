import {
  FrameActions,
  FrameConnector,
  HostActions,
  renderDocument,
} from '@govtechsg/decentralized-renderer-react-components';
import { OpenAttestationDocument, getData, utils, wrapDocument } from '@govtechsg/open-attestation';
import { WrappedDocument } from '@govtechsg/open-attestation/dist/types/2.0/types';
import {
  OpenAttestationDocument as OpenAttestationDocumentV2,
  TemplateType,
} from '@govtechsg/open-attestation/dist/types/__generated__/schema.2.0';
import { CircularProgress } from '@mui/material';
import _ from 'lodash';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { defaultDnsProofLocation, getTokenRegistryAddress } from '../../TradeTrust/tokenRegistry';
import './DocumentRenderer.css';

const SCROLLBAR_WIDTH = 100;
const DEFAULT_RENDERER_URL = `https://generic-templates.tradetrust.io`;

export interface TemplateProps {
  id: string;
  label: string;
  type: string;
}

export type Dispatch = (action: HostActions) => void;

export const DocumentRenderer = ({
  wrappedDocument,
  documentName = 'Document',
  overrideTemplateType,
  overrideDocumentData,
  overrideTemplateUrl,
}: {
  wrappedDocument: WrappedDocument<any> | any | undefined;
  documentName?: string;
  overrideTemplateType?: string;
  overrideDocumentData?: any;
  overrideTemplateUrl?: string;
  jsonBottomElement?: ReactElement;
}): ReactElement => {
  const [document, setDocument] = useState<OpenAttestationDocument>();
  const [wrappedDocumentState, setWrappedDocumentState] = useState<WrappedDocument>();

  const toFrame = useRef<Dispatch>();
  const [height, setHeight] = useState(250);
  const [, setIsTimeout] = useState(false);
  const [source, setSource] = useState<string>();

  const onConnected = useCallback(
    (frame: any) => {
      toFrame.current = frame;
      if (toFrame.current && document && wrappedDocumentState) {
        toFrame.current(renderDocument({ document, rawDocument: wrappedDocumentState }));
      }
    },
    [document, wrappedDocumentState],
  );

  const dispatch = (action: FrameActions): void => {
    if (action.type === 'UPDATE_HEIGHT') {
      setHeight(action.payload + SCROLLBAR_WIDTH);
    }

    if (action.type === 'TIMEOUT') {
      setIsTimeout(true);
    }
  };

  useEffect(() => {
    // Function to handle document load when overrideDocumentData is provided
    const handleOverrideDocumentData = () => {
      const newDocumentData = createDocumentData();
      setDocument(newDocumentData);
      const wrappedDocument = wrapDocument(newDocumentData);
      setWrappedDocumentState(wrappedDocument);
      const source = overrideTemplateUrl ?? DEFAULT_RENDERER_URL;
      setSource(source);
    };

    // Function to create a new document data structure
    const createDocumentData = () => {
      return {
        $template: {
          type: 'EMBEDDED_RENDERER' as TemplateType,
          name: overrideTemplateType ?? 'BOOKING_REQUEST',
          url: 'https://decentralized-renderer.ebl.dev/',
        },
        issuers: [
          {
            name: 'eBL test',
            // Token registry deployed on Polygon Mumbai and on Ethereum Sepolia test networks
            tokenRegistry: getTokenRegistryAddress(parseInt(window.ethereum?.networkVersion)),
            identityProof: {
              type: 'DNS-TXT',
              location: defaultDnsProofLocation,
            },
          },
        ],
        // TODO documentUrl is not complete
        documentUrl: 'localhost:3001/api/v2/transport-documents/',
        id: '',
        oaDocument: overrideDocumentData,
      } as OpenAttestationDocumentV2;
    };

    // Function to handle document load when wrappedDocument is provided
    const handleWrappedDocument = () => {
      setWrappedDocumentState(wrappedDocument);
      const source = getTemplateUrl(wrappedDocument) ?? DEFAULT_RENDERER_URL;
      setSource(source);
      const documentData = getData(wrappedDocument as WrappedDocument);
      const updatedDocument = overrideTemplateType
        ? _.set(documentData, '$template.name', overrideTemplateType)
        : documentData;
      setDocument(updatedDocument);
    };

    // Main logic for useEffect
    if (overrideDocumentData && !wrappedDocument) {
      handleOverrideDocumentData();
    } else if (wrappedDocument) {
      handleWrappedDocument();
    }
  }, [wrappedDocument, overrideDocumentData, overrideTemplateType, overrideTemplateUrl]);

  useEffect(() => {
    const renderDocumentToFrame = () => {
      const frame = toFrame.current;
      const currentDocument = document;
      const currentWrappedDocumentState = wrappedDocumentState;
      const canRenderDocument = frame && currentDocument && currentWrappedDocumentState;

      if (canRenderDocument) {
        frame(
          renderDocument({ document: currentDocument, rawDocument: currentWrappedDocumentState }),
        );
      }
    };

    renderDocumentToFrame();
  }, [document, wrappedDocumentState]);

  const getTemplateUrl = (rawDocument: WrappedDocument): string | undefined => {
    if (utils.isWrappedV2Document(rawDocument)) {
      const documentData = getData(rawDocument);
      return typeof documentData.$template === 'object' ? documentData.$template.url : undefined;
    }
  };

  const panes = [
    {
      render: () => (
        <>
          <div id="renderer-print-version">
            {source && (
              <FrameConnector
                style={{
                  zIndex: 99999,
                  width: '100%',
                  height: '1000px',
                  border: '0px',
                  padding: '0px',
                }}
                source={source}
                dispatch={dispatch}
                onConnected={onConnected}
              />
            )}
          </div>

          <div className="noprint">
            {(source && (
              <>
                <FrameConnector
                  style={{
                    height: `${height}px`,
                    width: '100%',
                    border: '0px',
                    padding: '20px',
                  }}
                  source={source}
                  dispatch={dispatch}
                  onConnected={onConnected}
                />
              </>
            )) ||
              (source === undefined && <CircularProgress />) || (
                <h3>{`Could not load ${documentName} render.`}</h3>
              )}
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      {panes.map((p) => {
        return p.render();
      })}
    </>
  );
};
